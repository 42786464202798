.dashboard-page-wrapper{
    @include bp(smd-max){
        padding-top: 15px;
    }
    .dashboard-grid{
        display: flex;
        margin: 0 -8px -16px;
        flex-wrap: wrap;
        .dashboard-grid-block{
            flex: 0 0 33.33%;
            max-width: 33.33%;
            padding: 0 8px 16px;
            @include bp(smd-max){
                flex: 0 0 50%;
                max-width: 50%;
            }
            @include bp(xs-max){
                flex: 0 0 100%;
                max-width: 100%;
            }
            .dashboard-grid-inner-block{
                padding: 20px 20px 15px;
                background-color:#E3F2FD ;
                border: 1px solid #A8D8FC;
                border-radius: 20px;
                height: 100%;
                @include bp(lg-max){
                    padding: 15px 15px 10px;
                    border-radius: 15px;
                }
                &.purple-card{
                    border: 1px solid #D5C6FF;
                    background-color: #F3EFFF;
                }
                &.yellow-card{
                    border: 1px solid #F8E3A1;
                    background-color: #FFF8E1;
                }
                .title-wrapper{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 16px;
                    @include bp(sm-max){
                        margin-bottom: 10px;
                    }
                    p{
                        font-size: 18px;
                        line-height: 1;
                        margin-right: 5px;
                        @include bp(lg-max){
                           font-size: 16px;
                        }
                    }
                    em{
                        display: block;
                        min-width: 22px;
                    }
                }
                span{
                    font-size: 30px;
                    line-height: 1;
                    @include bp(lg-max){
                        font-size: 26px;
                     }
                }
            }
        }
        &.dashboard-chart-grid{
            margin-top: 16px;
            .dashboard-grid-block{
                flex: 0 0 50%;
                max-width: 50%;
                @include bp(sm-max){
                    flex: 0 0 100%;
                    max-width: 100%;
                }
                &.has-full-width{
                    flex: 0 0 100%;
                   max-width: 100%;  
                }
                .dashboard-chart-grid-inner{
                    min-height: 300px;
                    background: #FFFFFF;
                    border-radius: 20px;
                    padding: 20px;
                    @include bp(lg-max){
                        padding: 15px;
                        border-radius: 15px;
                    }
                    .title{
                        display: flex;
                        align-items: center;
                        margin-bottom: 15px;
                        p{
                            font-size: 18px;
                            line-height: 1;
                            position: relative;
                            top: 2px;
                            @include bp(lg-max){
                                font-size: 16px;
                             }
                        }
                        em{
                            display: block;
                            margin-right: 10px;
                            min-width: 22px;
                        }
                    }
                }
            }            
        }
    }
}