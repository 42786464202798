.astrict-sign {
  color: #ff4444;
}

.authentication-wrapper {
  height: 100%;
  // padding: 2rem 15rem 4rem 4rem;
  padding: 3rem 15rem 3rem 4rem;
  background-color: #fff;
  background-image: url('../../images/login-background.jpg');
  background-position: center bottom;
  background-size: cover;
  @include bp(llg-max) {
    padding-right: 10rem;
  }
  @include bp(lg-max) {
    padding: 3rem 4rem 3rem;
  }
  @include bp(sm-max) {
    padding: 3rem 1.5rem 3rem;
  }
  .dark-theme & {
    background-color: #0e0c23;
  }
  .tatva-logo-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.1rem;
    font-weight: 300;
    gap: 0.8rem;
    color: #333333;
    margin-top: 2.6rem;
    .dark-theme & {
      color: rgba(228, 230, 244, 1);
    }
    img {
      max-width: 9rem;
      display: block;
      .dark-theme & {
        display: none;
      }
      &.dark-theme-img {
        display: none;
        .dark-theme & {
          display: block;
        }
      }
    }
  }
  .authentication-inner {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    min-height: calc(100vh - 6rem);
    @include bp(lg-max) {
      justify-content: center;
    }
    .login-container {
      max-width: 47rem;
      width: 100%;
      background-color: #fff;
      padding: 5rem;
      border-radius: 2.5rem;
      box-shadow: rgba(102, 102, 102, 0.7) 0 2rem 3rem -1rem;
      @include bp(sm-max) {
        padding: 2.5rem;
      }
      .dark-theme & {
        background-color: #18162b;
      }
      .site-logo {
        margin: 0 auto 2.8rem;
        display: block;
        text-align: center;
        @include bp(sm-max) {
          margin-bottom: 2.4rem;
        }
        img {
          // width: 19rem;
          margin: 0 auto;
          .dark-theme & {
            display: none;
          }
          &.dark-theme-logo {
            display: none;
            .dark-theme & {
              display: block;
            }
          }
        }
      }
      h2 {
        font-weight: 400;
        font-size: 2.2rem;
        line-height: 2.8rem;
        color: #333333;
        margin: 0 0 1.2rem;
        text-align: center;
        @include bp(sm-max) {
          font-size: 2rem;
          margin-bottom: 0.8rem;
        }
        .dark-theme & {
          color: rgba(228, 230, 244, 1);
        }
      }
      p {
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 2rem;
        color: #787878;
        margin-bottom: 2.5rem;
        text-align: center;
        @include bp(sm-max) {
          font-size: 1.4rem;
          margin-bottom: 1.8rem;
        }
        .dark-theme & {
          color: rgba(228, 230, 244, 0.68);
        }
      }
      form {
        .field {
          margin-bottom: 1.6rem;
          &.pwd {
            margin-bottom: 0.8rem;
          }
          .p-inputtext {
            font-weight: 400;
            font-size: 1.4rem;
            padding-right: 4.1rem;
            .dark-theme & {
              color: #e4e6f4;
              border-color: #2c2a40;
            }
            &:hover, &:focus {
              border-color: var(--secondary-color);
            }
            &::-webkit-input-placeholder {
              .dark-theme & {
                color: #767992;
              }
            }

            &:-moz-placeholder {
              .dark-theme & {
                color: #767992;
              }
            }

            &::-moz-placeholder {
              .dark-theme & {
                color: #767992;
              }
            }

            &:-ms-input-placeholder {
              .dark-theme & {
                color: #767992;
              }
            }
            &.p-invalid {
              &:focus {
                border-color: #64b5f6 !important;
              }
            }
          }
          .p-password.p-invalid.p-component > .p-inputtext {
            &:focus {
              border-color: #64b5f6 !important;
            }
          }
          .p-input-icon-right {
            em{
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              right: 1.6rem;
              cursor: pointer;
            }
            .pi {
              color: var(--black2);
              top: 50%;
              transform: translateY(-50%);
              font-size: 1.6rem;
              right: 1.6rem;
              margin: 0;
              .dark-theme & {
                color: #767992;
              }
            }
          }
          > label span {
            font-weight: 300;
            font-size: 1.3rem;
            line-height: 1.5rem;
            color: #333333;
            margin-bottom: 0.5rem;
            .dark-theme & {
              color: rgba(228, 230, 244, 1);
            }
            &.input-focus {
              color: var(--secondary-color);
            }
          }
        }
        .center {
          text-align: center !important;
          justify-content: center !important;

          span {
            margin-right: 0.6rem;
          }
        }
        .link-wrapper {
          margin-top: 1.6rem;
          margin-bottom: 1.6rem;
          text-align: right;
          justify-content: flex-end;
          &.back-login {
            text-align: center;
            margin-top: 3.2rem;
            a {
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 1.4rem;
              .pi-angle-left {
                font-size: 1.8rem;
                padding-right: 0.3rem;
              }
            }
          }
          a {
            font-weight: 400;
            font-size: 1.4rem;
            line-height: 1.8rem;
            color: var(--secondary-color);
            .dark-theme & {
              color: #9085ff;
            }
          }
        }
        button {
          margin: 0;
          font-family: 'Public Sans', sans-serif;
          display: flex;
          justify-content: center;
          &:hover{
            background-color: var(--secondary-color);
            border-color: var(--secondary-color);
          }
          &.Mui-disabled {
            color: #1e1e1e;
          }
        }
      }
    }
  }
  &.forgot-pwd {
    .authentication-inner {
      .right-col {
        button {
          margin-bottom: 3rem;
        }
      }
    }
  }
  &.reset-pwd {
    .authentication-inner {
      .right-col {
        h2 {
          margin-bottom: 1.8rem;
        }
      }
    }
  }
  &.singup-page {
    .radio-list-wrapper {
      .form-group-outer-radiobutton {
        label {
          font-size: 1.3rem;
        }
      }
    }
    .authentication-inner {
      .login-container {
        form {
          .field {
            &.pwd {
              margin-bottom: 1.6rem;
            }
          }
        }
      }
    }
  }
}

.space-between {
  display: flex;
  justify-content: space-between;
}

.keep-me-sign-in {
  display: flex;
  align-items: center;
  position: relative;
  .styled-checkbox {
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
    font-size: 0;
    left: 0;
    right: 0;
    z-index: 1;
    cursor: pointer;

    & + label {
      position: relative;
      cursor: pointer;
      padding: 0;
      font-size: 1.4rem;
    }

    // Box.
    & + label:before {
      content: '';
      margin-right: 0.6rem;
      display: inline-block;
      vertical-align: text-top;
      width: 1.7rem;
      height: 1.7rem;
      background: white;
      border: 0.2rem solid #ececec;
      border-radius: 0.4rem;
    }

    // Box checked
    &:checked + label:before {
      background: var(--primary-color);
      border-color: var(--primary-color);
    }
    &:checked + label:after {
      content: '';
      position: absolute;
      left: 0.7rem;
      top: 0.2rem;
      width: 0.4rem;
      height: 1rem;
      border: solid #fff;
      border-width: 0 0.2rem 0.2rem 0;
      transform: rotate(45deg);
    }
  }
}
