.site-footer {
  background-color: #0b2e58;
  .footer-lower-part {
    border-top: 0.1rem solid rgba(255, 255, 255, 0.08);
    .footer-lower-content-wrapper {
      padding: 3.4rem 0 3.9rem;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-end;
      justify-content: space-between;
      @include bp(lg-max){
        padding: 30px 0;
      }
      @include bp(sm-max){
        padding: 20px 0;
      }
      .copy-right {
        @include bp(lgl-max){
          flex: 0 0 70%;
          max-width: 70%;
        }
        @include bp(lg-max){
          flex: 0 0 76%;
          max-width: 76%;
        }
        @include bp(smd-max){
          flex: 0 0 100%;
          max-width: 100%;
          text-align: center;
          margin-bottom: 15px;
        }
        ul {
          margin-bottom: 1.7rem;
          line-height: 0;
          @include bp(smd-max){
            margin-bottom: 8px;
          }
          li {
            position: relative;
            display: inline-block;
            font-size: 1.4rem;
            line-height: 1.2;
            color: #4f4f4f;
            font-weight: 400;
            margin-right: 1.5rem;
            margin-bottom: 0.5rem;
            &:last-child {
              margin-right: 0;
            }
            &:not(:last-child) {
              a {
                &::after {
                  content: '';
                  position: absolute;
                  left: auto;
                  top: 50%;
                  transform: translateY(-50%);
                  bottom: auto;
                  right: -1.1rem;
                  width: 0.3rem;
                  height: 0.3rem;
                  background-color: #9fd9ff;
                  opacity: 0.8;
                }
              }
            }
            a {
              display: inline-block;
              color: #a9c5d7;
              font-weight: 400;
            }
          }
        }
        p {
          color: #9fd9ff;
          font-weight: 400;
          font-size: 1.4rem;
          line-height: 1.2;
          opacity: 0.8;
          a {
            color: #9fd9ff;
            font-size: inherit;
            @include bp(xxs-max){
              display: block;
              margin-top: 8px;
            }
          }
        }
      }
      .social-icons {
        @include bp(lgl-max){
          flex: 0 0 15%;
          max-width: 15%;
        }
          @include bp(lg-max){
            flex: 0 0 12%;
            max-width: 12%;
          }
          @include bp(smd-max){
            flex: 0 0 100%;
            max-width: 100%;
            text-align: center;
            margin-bottom: 10px;
          }
        ul {
          padding-left: 2.5rem;
          @include bp(lg-max){
            padding-left: 0;
          }
          li {
            margin: 0.5rem 2rem 0 0;
            display: inline-block;
            &:last-child {
              margin-right: 0;
            }
            a {
              display: inline-block;
              transition: 0.3s;
              opacity: 0.6;
              &:hover {
                opacity: 1;
              }
              img {
                width: 100%;
                height: 100%;
              }
              &.facebook{
                svg{
                  @include bp(lg-max){
                    max-width: 11px;
                    height: 18px;
                  }
                }
              }
              &.twitter{
                svg{
                  @include bp(lg-max){                    
                    max-width: 19px;
                    height: 15px;
                  }
                }
              }
              &.linkedin{
                @include bp(lg-max){                    
                  svg{
                    max-width: 18px;
                    height: 17px;
                  }
                }
              }
            }
          }
        }
      }
      .dmca-logo{
        @include bp(lgl-max){
          flex: 0 0 15%;
          max-width: 15%;
        }
        @include bp(lg-max){
          flex: 0 0 12%;
          max-width: 12%;
        }
        @include bp(smd-max){
          flex: 0 0 100%;
          max-width: 100%;
          text-align: center;
        }
      }
    }
  }
}
