.contact-page-container {
  .site-header {
    // background-color: #fff;
    padding: 6px 20px;
    .contact-wrapper {
      padding: 8px 0;
    }
  }
  .contact-page {
    background-color: #0b2e58;
    padding: 66px 0 82px;
    background-image: url(../../images/contact-banner.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    @include bp(lg-max) {
      padding-bottom: 50px;
    }
    @include bp(sm-max) {
      padding-bottom: 40px;
    }
    .contact-page-contant-container {
      padding: 0 25px;
      background: none;
      max-width: 1250px;
      margin: 0 auto;
      .authentication-inner {
        min-height: unset;
        justify-content: start;
        align-items: flex-start;
        padding-top: 60px;
        @include bp(lg-max) {
          padding-top: 40px;
        }
        @include bp(sm-max) {
          padding-top: 36px;
        }
        .left-col {
          flex: 0 0 51.1%;
          max-width: 51.1%;
          padding: 0 82px 0 0;
          @include bp(lg-max) {
            padding-right: 32px;
          }
          @include bp(sm-max) {
            flex: 0 0 100%;
            max-width: 100%;
            order: 2;
            padding-right: 0;
          }
          .call-mail-block {
            li {
              + li {
                margin-top: 30px;
                @include bp(sm-max) {
                  margin-top: 20px;
                }
              }
              span {
                display: block;
                color: #78c1f0;
                font-size: 22px;
                line-height: 1.45;
                margin-bottom: 4px;
                @include bp(lg-max) {
                  font-size: 20px;
                }
                @include bp(smd-max) {
                  font-size: 18px;
                }
              }
            }
            a {
              color: #fff;
              font-size: 20px;
              display: inline-block;
              text-decoration: underline;
              &:hover {
                text-decoration: none;
              }
              @include bp(lg-max) {
                font-size: 18px;
              }
              @include bp(smd-max) {
                font-size: 16px;
              }
            }
          }
          .heading-block {
            margin: 59px 0 65px;
            @include bp(smd-max) {
              margin: 59px 0 65px;
            }
            @include bp(sm-max) {
              margin: 30px 0 15px;
            }
            h1 {
              font-size: 60px;
              color: #fff;
              margin: 0 0 50px;
              text-shadow: 0 0 15px rgba(0, 0, 0, 0.7);
              text-transform: uppercase;
              @include bp(lg-max) {
                font-size: 40px;
              }
              @include bp(smd-max) {
                font-size: 36px;
                margin: 0 0 40px;
              }
              @include bp(sm-max) {
                font-size: 28px;
                margin: 0 0 15px;
              }
            }
            p {
              font-size: 22px;
              line-height: 1.36;
              color: #fff;
              @include bp(lg-max) {
                font-size: 20px;
              }
              @include bp(smd-max) {
                font-size: 18px;
              }
            }
          }
        }
        .right-col {
          flex: 0 0 48.9%;
          max-width: 48.9%;
          @include bp(sm-max) {
            flex: 0 0 100%;
            max-width: 100%;
            order: 1;
          }
          h2 {
            font-size: 40px;
            @include bp(lg-max) {
              font-size: 36px;
            }
            @include bp(smd-max) {
              font-size: 32px;
            }
            @include bp(sm-max) {
              font-size: 28px;
            }
          }
          .login-container {
            max-width: 100%;
            @include bp(smd-max) {
              padding: 30px;
            }
            @include bp(sm-max) {
              padding: 25px;
            }
          }
        }
      }
    }
  }
}
