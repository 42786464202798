$error-text-color: #e24c4c !important;

.counter-limit-exceeded,
.text-area-formik-error {
  color: $error-text-color;
}
.text-area-flex-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.text-area-limit-text-setter {
  padding: 0 !important;
  font-size: 13.3333px !important;
}
