.job-page {
  display: flex;
  flex-direction: column;
  padding: 60px 20px;
  background-color: #ffffff;
  @include bp(lg-max){
    padding: 40px 20px;
  }
  .search-input {
    background-color: #f8f8f8;
    padding: 12px;
    border-radius: 5px;
    width: 100%;
    &.p-input-icon-left {
      .search-icon {
        top: 47%;
        left: 2.2rem;
      }
      .p-inputtext {
        background-color: #ffffff;
      }
    }
  }
}

.job-content {
  display: flex;
  gap: 30px;
  align-items: flex-start;
  @include bp(sm-max){
   flex-wrap: wrap; 
  }
}

.filter-section {
  width: 25rem;
  background-color: #f8f8f8;
  padding: 2rem;
  border-radius: 0.8rem;  
  position: sticky;
  top: 7rem;
  max-height: calc(100vh - 4rem);
  overflow-y: auto;
  @include bp(sm-max){
    width: 100%;
    position: relative;
    top: 0;
  }
  .p-radiobutton-input {
    border: 0;
  }
  .p-slider {
    &:not(.p-disabled){
      .p-slider-handle{
        &:hover{
          background: var(--primary-color);
          border-color: var(--primary-color);
        }
      }
    }
    .p-slider-range {
      background: var(--primary-color);
    }
    .p-slider-handle {
      border-color: var(--primary-color);
      &.p-slider-handle-start{
        margin-left: 0;
      }
      &.p-slider-handle-end{
        margin-left: -1rem;
      }
    }
  }
  h3 {
    margin-bottom: 0px;
    color: #333;
    font-size: 18px;
    font-weight: 500;
  }

  .filter {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 0px;
    }
    & + .filter {
      margin-top: 25px;
    }
    .field-checkbox{
      &:last-child{
        margin-bottom: 0;
      }
    }
  }

  .p-field-radiobutton {
    display: flex;
    align-items: center;
    gap: 10px;

    label {
      color: #555;
      cursor: pointer;
    }
  }
}

.job-list-container {
  flex: 1;
  display: flex;
  flex-direction: column;  
  @include bp(sm-max){
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.searchBox{
  .search-icon{
    height: 100%;
    display: flex;
    align-items: center;
  }
  .p-inputtext{
    &:enabled{
      &:focus, &:hover{
        border-color: var(--secondary-color);
      }
    }
  }
}

.p-input-icon-left {
  @include bp(sm-max) {
    order: 2;
    display: block;
    width: 100%;
  }

  &.search-input {
    .search-icon {
      margin-top: 0;
      transform: translateY(-50%);
      color: var(--light-gray2);
      font-size: 1.4rem;
      padding-left: 0;
      position: absolute;
      top: 46%;
      left: 0.9rem;

      .dark-theme & {
        color: var(--title-light-text);
      }
    }

    .p-inputtext {
      width: 38.5rem;
      line-height: 4.7rem;
      padding: 0 1rem 0 2.8rem;

      // transition: all 0.5s;
      @include bp(sm-max) {
        font-size: 1.5rem;
      }

      .dark-theme & {
        color: #e4e6f4;
        border-color: #2c2a40;
      }

      &:focus {
        border-color: var(--secondary-color);
        &::-webkit-input-placeholder {
          opacity: 0 !important;
        }

        &:-moz-placeholder {
          opacity: 0 !important;
        }

        &:-ms-input-placeholder {
          opacity: 0 !important;
        }
      }
      &:hover {
        border-color: var(--secondary-color);
      }
      &::-webkit-input-placeholder {
        color: var(--light-gray2);
        opacity: 1 !important;

        .dark-theme & {
          color: #767992;
        }
      }

      &:-moz-placeholder {
        color: var(--light-gray2);
        opacity: 1 !important;

        .dark-theme & {
          color: #767992;
        }
      }

      &::-moz-placeholder {
        color: var(--light-gray2);
        opacity: 1 !important;

        .dark-theme & {
          color: #767992;
        }
      }

      &:-ms-input-placeholder {
        color: var(--light-gray2);
        opacity: 1 !important;

        .dark-theme & {
          color: #767992;
        }
      }

      &::-webkit-search-decoration {
        display: none;
      }

      &::-webkit-search-cancel-button {
        display: none;
      }

      &::-webkit-search-results-button {
        display: none;
      }

      &::-webkit-search-results-decoration {
        display: none;
      }

      @include bp(xxlg-max) {
        width: 27rem;
      }

      @include bp(xxs-max) {
        width: 100%;
      }
    }
  }
}

.loading-container {
  display: flex;
  justify-content: center;

  .p-progress-spinner {
    margin: 10px;
    width: 50px;
    height: 50px;
  }
}
.no-data-found {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  font-weight: 500;
  color: #333;
  margin-top: 15px !important;
}

// @media (max-width: 768px) {
//   .job-content {
//     flex-direction: column;
//   }

//   .filter-section {
//     width: 100%;
//     margin-bottom: 20px;
//   }
// }

.job-mode-dropdown {
  width: 100%;
}
.job-skill-badge {
  margin: 3px;
  padding: 0 1rem;
  background-color: var(--primary-color) ;
  color: #ffffff;

  span {
    font-size: 12px;
  }
}
.p-checkbox-input 
{
  appearance: auto !important;
}
.slider-labels{
  display: flex;
  justify-content: space-between;
  font-size: 1.1rem;
}
.slider-values{
  font-size: small;
}